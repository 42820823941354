import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Heading from "../../utils/heading"

const Wrapper = styled.div.attrs({
    className: "contentBlockWrapper container",
})`
    ${props => props.theme.wrappersContainers.primaryContainer};
    overflow:auto;

    .inner{
        ${props => props.theme.wrappersContainers.primaryWrapper};

        .title{
            ${props => props.theme.fonts.textIndent};
            ${props => props.theme.fonts.h1};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .subTitle{
            ${props => props.theme.fonts.h2};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .subSubTitle{
            ${props => props.theme.fonts.h4};
            ${props => props.theme.fonts.primaryFont};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .contentWrapper{

            &.twoThirds{
                @media ${props => props.theme.mq.md} {
                    width:66%;
                }
            }

            &.twoColumn{
                width:100%;
                @media ${props => props.theme.mq.md} {
                    column-count: 2;
                    column-gap: 3rem;
                }
            }

            .linkButton{
                ${props => props.theme.buttons.primary};
                float:right;
                margin-top:1rem;
            }

        }

    }

`


const Content = props => {

    return (

        <Wrapper>
            <div className="inner">

                {props.titleTitle &&
                    <Heading type="title" as={props.titleTag} color={props => props.theme.colors.tertiary}>
                        {props.titleTitle}
                    </Heading>
                }
                {props.subTitleTitle &&
                    <Heading type="subTitle" as={props.subTitleTag} color={props => props.theme.colors.secondary}>
                        {props.subTitleTitle}
                    </Heading>
                }
                {props.subSubTitleTitle &&
                    <Heading type="subSubTitle" as={props.subSubTitleTag} color={props => props.theme.colors.secondary}>
                        {props.subSubTitleTitle}
                    </Heading>
                }
                {props.content &&
                    <div className={"contentWrapper " + props.contentLayout + " " + props.contentWidth} >

                        <div dangerouslySetInnerHTML={{ __html: props.content }} />

                        {props.link &&
                            <Link className="linkButton" to={props.link.url.replace(process.env.GATSBY_CMS_ADDR, '')} title={props.link.title} target={props.link.target}>
                                {props.link.title}
                            </Link>
                        }

                    </div>
                }

            </div>
        </Wrapper>

    )

}

export default Content