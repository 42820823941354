import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Heading from "../../utils/heading"
import Gallery from "../../utils/gallery"

const Wrapper = styled.div.attrs({
    className: "sideBySideWrapper container",
})`
    ${props => props.theme.wrappersContainers.primaryContainer};
    overflow:auto;

    .inner{
        ${props => props.theme.wrappersContainers.primaryWrapper};

        .title{
            ${props => props.theme.fonts.textIndent};
            ${props => props.theme.fonts.h1};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .subTitle{
            ${props => props.theme.fonts.h2};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .subSubTitle{
            ${props => props.theme.fonts.h4};
            ${props => props.theme.fonts.primaryFont};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .contentWrapper{
            display: grid;
            grid-gap: 1rem;
            grid-template-rows: auto auto;
            grid-template-columns: 1fr;
            width: 100%;
            grid-template-areas:
                    "text"
                    "gallery";

            &.textRight {

                .linkButton{
                    float:right;
                }
            }

            @media ${props => props.theme.mq.sm} {
                grid-gap: 3rem;
                grid-template-rows: auto;

                &.textLeft {
                    grid-template-areas: "text gallery";
                    grid-template-columns: 3fr 2fr;
                }

                &.textRight {
                    grid-template-areas: "gallery text";
                    grid-template-columns: 2fr 3fr;
                }
            }

            @media ${props => props.theme.mq.lg} {
                grid-gap: 5rem;
            }

            .text {
                grid-area: text;
            }

            .galleryWrapper {
                grid-area: gallery;


                @media ${props => props.theme.mq.sm} {

                    &.gallery-2, &.gallery-3, &.gallery-4{
                        display:flex;
                        flex-wrap:wrap;
                        flex-direction: column;
                        
                        .item-0 {
                            margin-right: auto;
                        }

                        .item-1 {
                            margin-left: auto;
                            margin-top:-3rem;
                        }

                        .item-2 {
                            margin-right: auto;
                            margin-top:-3rem;
                        }

                        .item-3 {
                            margin-left: auto;
                            margin-top:-3rem;
                        }

                        .galleryItem{
                            width:80%;
                        }
                    }

                }

                .galleryItem{
                    height:20rem;

                    @media ${props => props.theme.mq.sm} {
                        height:25vw;
                        max-height:22rem;
                    }

                }

                span{
                    display:block;
                    position: absolute;
                    top:0;
                    left:0;
                    color:white;
                    padding: 0.5rem 0.5rem 0.5rem 2.2rem;

                    &::before{
                        position: absolute;
                        top:0.5rem;
                        left:0.5rem;
                        content: '';
                        width: 1.5rem;
                        height: 1.5rem;
                        background-image: url(/svgs/icons/map-marker.svg);
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }

            }

            .linkButton{
                ${props => props.theme.buttons.primary};
                margin-top:1rem;
            }

        }

    }

`

const SideBySide = props => {

    return (

        <Wrapper>
            <div className="inner">

                {props.titleTitle &&
                    <Heading type="title" as={props.titleTag} color={props => props.theme.colors.tertiary}>
                        {props.titleTitle}
                    </Heading>
                }
                {props.subTitleTitle &&
                    <Heading type="subTitle" as={props.subTitleTag} color={props => props.theme.colors.secondary}>
                        {props.subTitleTitle}
                    </Heading>
                }

                {props.content &&
                    <div className={"contentWrapper " + props.orientation}>

                        <div className="text">

                            <div dangerouslySetInnerHTML={{ __html: props.content }} />

                            {props.link &&
                                <Link className="linkButton" to={props.link.url} title={props.link.title} target={props.link.target}>
                                    {props.link.title}
                                </Link>
                            }

                        </div>

                        <Gallery content={props} icon="map-marker" />

                    </div>
                }

            </div>
        </Wrapper>

    )

}

export default SideBySide