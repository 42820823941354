import React from "react"

import HeaderBlock from "../components/headerBlock/headerBlock"
import NavigationSlider from "../components/navigationSlider/navigationSlider"
import CmsPropertySelector from "../components/cms/cmsPropertySelector/cmsPropertySelector"
import Content from "../components/content/content"
import SideBySide from "../components/sideBySide/sideBySide"


const CMS = ({ children }) => {

    function isModule(moduletype, testname) {
        if (moduletype.indexOf(testname) >= 0) {
            return true
        } else {
            return false
        }
    }

    if (children != null) {
        return (
            <>
                {children.map((child, i) => {

                    //header block
                    if (isModule(child.__typename, "_Modules_HeaderBlock")) {
                        return <HeaderBlock {...child} key={child + i} />
                    }

                    //content
                    else if (isModule(child.__typename, "_Modules_Content")) {
                        return <Content {...child} key={child + i} />
                    }

                    //side by side
                    else if (isModule(child.__typename, "_Modules_SideBySide")) {
                        return <SideBySide {...child} key={child + i} />
                    }

                    //navigation slider
                    else if (isModule(child.__typename, "_Modules_NavigationSlider")) {
                        return <NavigationSlider {...child} key={child + i} />
                    }

                    //property selector
                    else if (isModule(child.__typename, "_Modules_PropertySelector")) {
                        return <CmsPropertySelector {...child} key={child + i} />
                    }
                    else
                        return null

                })}
            </>
        )
    } else {
        return null
    }
}

export default CMS