import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Heading from "../../utils/heading"

const Wrapper = styled.div.attrs({
  className: "headerBlockWrapper container",
})`
  ${(props) => props.theme.wrappersContainers.primaryContainer};
  overflow: auto;

  .inner {
    ${(props) => props.theme.wrappersContainers.primaryWrapper};

    .gridWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      margin: 0 -0.5rem;

      a {
        text-decoration: none;
      }

      .col {
        padding: 0.5rem;
        position: relative;

        .image.in {
          position: relative;
          display: block;
          color: ${(props) => props.theme.colors.primaryFontColor};
          overflow: hidden;
          border-radius: 0.25rem;

          ${(props) => props.theme.fonts.smallerFontSize};
          height: 12rem;

          @media ${(props) => props.theme.mq.sm} {
            height: 22rem;
            font-size: 100%;
          }

          &:hover .hover {
            transform: translateY(0);
          }

          .hover {
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.3s ease;
            transform: translateY(calc(100% - 56px));

            strong {
              background-color: rgba(255, 255, 255, 0.8);
              padding: 1rem;
              display: inline-block;
            }

            div {
              background-color: rgba(255, 255, 255, 0.8);
              width: 100%;
              clear: both;

              p {
                padding: 1rem;
                margin: 0;
              }
            }
          }

          .imageWrapper {
            height: 100%;
            z-index: 1;
          }
        }
      }

      .content {
        flex: 0 0 100%;

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 41.66667%;
        }

        .in {
          word-break: break-word;
          @media ${(props) => props.theme.mq.lg} {
            padding: 1rem 3rem 1rem 1rem;
          }
        }

        .title {
          ${(props) => props.theme.fonts.textIndent};
          ${(props) => props.theme.fonts.h1};
          margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .subTitle {
          ${(props) => props.theme.fonts.h3};
          margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

      }

      .imageOne {
        flex: 0 0 50%;

        @media ${(props) => props.theme.mq.sm} {
          flex: 0 0 41.66667%;
        }

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 25%;
          top: -2.8125rem;
        }

        strong {
          border-radius: 0 0.25rem 0 0;
        }
      }

      .imageTwo {
        flex: 0 0 50%;

        @media ${(props) => props.theme.mq.sm} {
          flex: 0 0 58.33333%;
        }

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 33.33333%;
          top: -2.8125rem;
        }

        strong {
          border-radius: 0 0.25rem 0 0;
        }
      }

      .imageThree {
        flex: 0 0 100%;

        @media ${(props) => props.theme.mq.sm} {
          flex: 0 0 58.33333%;
        }

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 41.66667%;
        }

        strong {
          border-radius: 0 0.25rem 0 0;
        }
      }

      .imageFour {
        flex: 0 0 50%;

        @media ${(props) => props.theme.mq.sm} {
          flex: 0 0 41.66667%;
        }

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 25%;
          top: -2.8125rem;
        }

        strong {
          border-radius: 0.25rem 0 0 0;
          float: right;
        }
      }

      .imageFive {
        flex: 0 0 50%;

        @media ${(props) => props.theme.mq.sm} {
          flex: 0 0 41.66667%;
        }

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 33.33333%;
          top: -2.8125rem;
        }

        strong {
          border-radius: 0.25rem 0 0 0;
          float: right;
        }
      }

      .imageSix {
        flex: 0 0 100%;

        @media ${(props) => props.theme.mq.sm} {
          flex: 0 0 58.33333%;
        }

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 50%;
        }

        strong {
          border-radius: 0.25rem 0 0 0;
          float: right;
        }
      }

      .imageSeven {
        flex: 0 0 100%;

        @media ${(props) => props.theme.mq.lg} {
          flex: 0 0 50%;
          top: -2.8125rem;
        }

        strong {
          border-radius: 0.25rem 0 0 0;
          float: right;
        }
      }
    }

    .footerLink {
      ${(props) => props.theme.buttons.primary};
      margin-top: 1rem;
    }
  }
`

const HeaderBlock = (props) => {
  const imgClasses = [
    "imageOne",
    "imageTwo",
    "imageThree",
    "imageFour",
    "imageFive",
    "imageSix",
    "imageSeven",
  ]

  return (
    <Wrapper>
      <div className="inner">
        <div className="gridWrapper">
          <div className="col content">
            <div className="in">
              {props.titleTitle && (
                <Heading
                  type="title"
                  as={props.titleTag}
                  color={(props) => props.theme.colors.tertiary}
                >
                  {props.titleTitle}
                </Heading>
              )}
              {props.subTitleTitle && (
                <Heading
                  type="subTitle"
                  as={props.subTitleTag}
                  color={(props) => props.theme.colors.secondary}
                >
                  {props.subTitleTitle}
                </Heading>
              )}
              {props.content && (
                <span dangerouslySetInnerHTML={{ __html: props.content }} />
              )}
            </div>
          </div>
          {props.images &&
            props.images.map((img, imgNo) => (
              <div className={"col " + imgClasses[imgNo]} key={imgNo}>
                {img.image && (
                  <Link
                    to={img.link?.uri}
                    title={img.title}
                    className="image in"
                  >
                    <div className="hover">
                      <strong>{img.title}</strong>
                      <div>
                        <p>{img.content}</p>
                      </div>
                    </div>
                    <div className="imageWrapper">
                      <Img fluid={img.image.localFile.childImageSharp.fluid} />
                    </div>
                  </Link>
                )}
              </div>
            ))}
        </div>
        {props.link && (
          <Link
            to={props.link.url.replace(process.env.GATSBY_CMS_ADDR, '')}
            title={props.link.title}
            className="footerLink"
          >
            {props.link.title}
          </Link>
        )}
      </div>
    </Wrapper>
  )
}

export default HeaderBlock
