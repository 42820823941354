import React from "react"
import styled from "styled-components"
//import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import PropertySelector from "../../property/propertySelector/propertySelector"
import Heading from "../../../utils/heading"

const Wrapper = styled.div.attrs({
    className: "propertySelectorWrapper container",
})`
    ${props => props.theme.wrappersContainers.primaryContainer};
    overflow:auto;

    .inner{
        ${props => props.theme.wrappersContainers.primaryWrapper};
        ${props => props.theme.wrappersContainers.primaryContainer};


        .title{
            ${props => props.theme.fonts.textIndent};
            ${props => props.theme.fonts.h1};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .subTitle{
            ${props => props.theme.fonts.h3};
            margin:0 0 1rem 0;
            @media ${props => props.theme.mq.md} {
                margin:0 0 2rem 0;
            }
        }

        .footerLink{
            ${props => props.theme.buttons.secondary};
            margin:1rem auto 0 auto;
        }

    }

`

const CmsPropertySelector = props => {

    // //get all the data
    // const data = useStaticQuery(graphql`
    //     query{
    //         allWpProperty {
    //             nodes {
    //                 title
    //                 propertyRef
    //             }
    //         }
    //     }
    // `)

    //fetch selected property refs
    function buildQuery() {

        // dev mode we get properties that have no ref so filter just in case
        var idList = props.properties?.filter(prop => prop?.propertyRef);

        // idList =idList.map((prop, i) => prop?.databaseId);

        // var selectedProperties = data.allWpProperty.nodes.filter(
        //     idz => idList.indexOf(idz.databaseId) >= 0
        // ).map((prop, i) => prop.propertyRef)

        var selectedProperties = idList.map((prop, i) => prop.propertyRef);

        const query = selectedProperties.length ? {
            which: {
                proprefs: selectedProperties
            }
        } : {}

        return query
    }

    if (!props?.properties)
        return null;

    return (
        <Wrapper>
            <div className="inner">

                {props.titleTitle &&
                    <Heading type="title" as={props.titleTag} color={props => props.theme.colors.tertiary}>
                        {props.titleTitle}
                    </Heading>
                }

                {props.subTitleTitle &&
                    <Heading type="subTitle" as={props.subTitleTag} color={props => props.theme.colors.secondary}>
                        {props.subTitleTitle}
                    </Heading>
                }

                <PropertySelector searchquery={buildQuery()} excludeIds={[]} maxno={6} />

                {props.link &&
                    <Link to={props.link.url.replace(process.env.GATSBY_CMS_ADDR, '')} title={props.link.title} className="footerLink">
                        {props.link.title}
                    </Link>
                }

            </div>
        </Wrapper>
    )
}

export default CmsPropertySelector