
import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Heading from "../../utils/heading"
import Icon from "../../utils/icon"

import Slider from "react-slick"
import "../../theme/components/slickSliderStyles/slick.scss"
import "../../theme/components/slickSliderStyles/slick-theme.scss"

const Wrapper = styled.div.attrs({
    className: "navigationSliderWrapper container",
})`
    ${props => props.theme.wrappersContainers.primaryContainer};
    overflow:auto;

    .navSliderWrapper{
        background-color: ${props => props.theme.colors.tertiary};
        padding:2rem 0;
        position: relative;
        margin-bottom:1rem;
         
        &:before {
            background: #fff;
            background: linear-gradient(#dbd8df 0, transparent 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbd8df",endColorstr="#ffffff",GradientType=0);
            content: " ";
            height: 15px;
            width: 100%;
            z-index:2;
            position: absolute;
            top:100%;
        }

        .navSlider{
            ${props => props.theme.wrappersContainers.primaryWrapper};

            .slick-list{
                margin:0 1rem;
                @media ${props => props.theme.mq.md} {
                    margin:0 3rem;
                }
            }

            .slick-arrow{

                &.slick-next{
                    right: 1rem;
                    @media ${props => props.theme.mq.md} {
                        right: 3rem;
                    }
                    &:before{
                        content:"";
                    }
                }

                &.slick-prev{
                    left: 0.5rem;
                    @media ${props => props.theme.mq.md} {
                        left: 3rem;
                    }
                    &:before{
                        content:"";
                    }
                }

                .svgWrapper{
                    position:absolute;
                    top:-0.4rem;
                    fill:${props => props.theme.colors.secondary};
                }

            }

        }
    }

    .slick-slider{
        margin-bottom:0!important;
        ${props => props.theme.wrappersContainers.primaryContainer};
        ${props => props.theme.wrappersContainers.primaryWrapper};

        &:before{

            @media ${props => props.theme.mq.md} {
                z-index:1;
                position: absolute;
                top:-3rem;
                content: " ";
                border-left: 1rem solid transparent;
                border-right: 1rem solid transparent;
                border-top: 1.5rem solid ${props => props.theme.colors.tertiary};
                left:28%;
            }

            @media ${props => props.theme.mq.lg} {
                left:19.8%;
            }
        }
    }

`

const Nav = styled.div.attrs({
    className: "nav",
})`

    text-align:center;
    text-transform: uppercase;
    font-weight: bold;
    ${props => props.theme.fonts.smallerFontSize};
    position: relative;
    cursor: pointer;

`

const Slide = styled.div.attrs({
    className: "slide",
})`

    .inner{

        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto auto;

        @media ${props => props.theme.mq.md} {
            grid-template-columns: 3fr 2fr;
            grid-auto-rows: 1fr;
        }

        .content{
            order: 2;

            @media ${props => props.theme.mq.md} {
                order: 1;
                padding-right:5rem;
            }
            
            .itemTitle{
                @media ${props => props.theme.mq.md} {
                    margin-top:0;
                }
            }

            .linkWrapper{
                display: flex;
                align-items: center;
                justify-items: center;

                a{
                    flex-basis: 100%;
                }

                .linkButton{
                    ${props => props.theme.buttons.primary};
                    text-align: center;
                }

                .linkText{
                    padding:0 1rem;
                    text-decoration: none;
                    text-align: left;
                    &:hover{
                        color:${props => props.theme.colors.secondary};
                    }
                }

            }
            
        }

        .imageWrapper{
            height:50vh;
            order: 1;

            @media ${props => props.theme.mq.md} {
                height:22rem;
                order: 2;
            }
        }

    }

`

function NextArrow(props) {
    const { className, onClick } = props
    return (
        <button className={className} onClick={onClick}>
            <Icon name="cheveron-right" width="2rem" />
        </button>
    )
}

function PrevArrow(props) {
    const { className, onClick } = props
    return (
        <button className={className} onClick={onClick}>
            <Icon name="cheveron-left" width="2rem" />
        </button>
    )
}

const settings = {
    lazyLoad: true,
    infinite: true,
    dots: false,
    arrows: true,
    fade: false,
    slidesToScroll: 1,
    pauseOnHover: true,
    adaptiveHeight: false,
    mobileFirst: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
}

const navSettings = {
    slidesToShow: 3,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
}

const NavigationSlider = props => {

    let slider1
    let slider2

    const [sliderState, setSliderState] = useState({
        nav1: null,
        nav2: null
    })

    useEffect(() => {

        setSliderState(setState => ({
            ...setState,
            nav1: slider1,
            nav2: slider2
        }))

    }, [slider1, slider2])

    return (
        <Wrapper>

            <div className="navSliderWrapper">
                <Slider {...settings}
                    {...navSettings}
                    asNavFor={sliderState.nav2}
                    ref={slider => (slider1 = slider)}
                    className="navSlider"
                >
                    {props.items.length && props.items.map((item, i) =>
                        <Nav key={i}>
                            {item.navigationTitle}
                        </Nav>
                    )}
                </Slider>
            </div>

            <Slider {...settings}
                asNavFor={sliderState.nav1}
                ref={slider => (slider2 = slider)}
                slidesToShow={1}
                arrows={false}
                fade={true}
            >
                {props.items.length && props.items.map((item, i) =>
                    <Slide key={i}>
                        <div className="inner">

                            <div className="content">

                                <Heading type="itemTitle" as="h3" color={props => props.theme.colors.secondary}>
                                    {item.title}
                                </Heading>

                                <div dangerouslySetInnerHTML={{ __html: item.content }} />

                                <div className="linkWrapper">

                                    {item.primaryLink &&
                                        <Link className="linkButton" to={item.primaryLink.url.replace(process.env.GATSBY_CMS_ADDR, '')} title={item.primaryLink.title} >
                                            {item.primaryLink.title}
                                        </Link>
                                    }

                                    {item.secondaryLink &&
                                        <Link className="linkText" to={item.secondaryLink.url.replace(process.env.GATSBY_CMS_ADDR, '')} title={item.secondaryLink.title} >
                                            {item.secondaryLink.title}
                                        </Link>
                                    }

                                </div>

                            </div>

                            <div className="imageWrapper">
                                <Img fluid={item.image.localFile.childImageSharp.fluid} />
                            </div>

                        </div>
                    </Slide>
                )}
            </Slider>

        </Wrapper>
    )
}

export default NavigationSlider